// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    enableTrace: true,

    appTitle: 'Common.App_Title_Test',
    appTagLine: 'Common.App_TagLine_Test',

    defaultAvatarPhoto: "/assets/img/avatar.png",
    hostDomain: "staging.hushtagsapp.com",
    defaultCoverPhoto: "/assets/img/cover.png",

    enableGoogleAnalytics: false,
    googleAnalyticsID: '',
    
    api: {
	    host: "https://staging-bff.hushtagsapp.com",
	    version: "current",
    },

    stripe: {
        key: 'pk_test_oSMzzdif1PRCBh9FArTeD1kY',
    },

    support: {
        email: 'help@hushtags.app',
        phone: '555-555-5555',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
