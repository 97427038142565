import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '../../../environments/environment';

import { AppSettingsService } from './app-settings.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AppSettingsModule {
	static forRoot(): ModuleWithProviders<AppSettingsModule> {
		return {
			ngModule: AppSettingsModule,
			providers: [AppSettingsService]
		}
	}
}

export class ApiSettings {
	static readonly host: string = environment.api.host; 
	static readonly apiVer: string = environment.api.version; 
}

export class GoogleAnalyticsSettings {
	static readonly enabled = environment.enableGoogleAnalytics;
	static readonly id = environment.googleAnalyticsID;
}

export class StripeSettings {
	static readonly key = environment.stripe.key;
}

export class Support {
	static readonly email = environment.support.email;
	static readonly phone = environment.support.phone;
}

export const EnableTrace = environment.enableTrace; 
export const AppTitle = environment.appTitle;
export const AppTagLine = environment.appTagLine;
export const DefaultAvatarPhoto = environment.defaultAvatarPhoto; 
export const HostDomain = environment.hostDomain; 
export const DefaultCoverPhoto = environment.defaultCoverPhoto;