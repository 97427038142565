import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppLogModule } from './include/app-log/app-log.module';
import { AppSettingsModule } from './include/app-settings/app-settings.module';
import { I18nModule, Translations } from './include/i18n/i18n.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    HttpClientModule,
    IonicModule.forRoot(), 
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/'), deps: [HttpClient] }
    }),
    I18nModule,
    AppLogModule.forRoot(),
    AppSettingsModule.forRoot(),    
    AppRoutingModule
  ],
  providers: [
    Translations,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
