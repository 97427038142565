import { ErrorHandler, Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { AppLogService } from './app-log.service';

@NgModule()
export class AppLogModule {
	constructor() {
	}

	static forRoot(): ModuleWithProviders<AppLogModule> {
		return {
			ngModule: AppLogModule,
			providers: [AppLogService]
		}
	}

}