import { Injectable, Output } from '@angular/core';

@Injectable()
export class AppSettingsService {
	constructor(
	) {
		this.browserVer = window.navigator.appVersion;
		this.usingIOS = this.browserVer.indexOf('iPhone') >= 0;
		this.usingAndroid = this.browserVer.indexOf('Android') >= 0;
	}

	@Output() browserVer: string;
	@Output() usingIOS: boolean;
	@Output() usingAndroid: boolean;

	get<T>(key: string): T {
		let v = JSON.parse(localStorage.getItem(key));

		if (v) {
			if (v.expire) {
				let expire = new Date(v.expire);

				if (expire > new Date()) {
					return v.value;
				}
			} else {
				return v.value as T;
			}
		}

		this.remove(key);

		return null;
	}

	set(key: string, value: AppSetting) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	remove(key: string) {
		localStorage.removeItem(key);
	}

	clear() {
		localStorage.clear();
	}

}

export class AppSetting {
	value: any
	expire?: Date
}

export const SettingKey = {
	ignore: 'ignore',
	location: 'location',
	locationPrompt: 'locationPrompt',
	cartHandle: 'cartHandle',
	paymentSession: 'paymentSession',
}

export interface IgnoreSetting {
	tag?: string[]
	ark?: string[]
}

export interface LocationSetting {
	lat: number
	lon: number
}